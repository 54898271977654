import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Contact from '../components/Contact';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';
import { useCardData } from '../components/CourseDataContext';

export default function CoursesDetails() {
  const { cardData } = useCardData();
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const selectedCard = cardData.find((card) => card.CourseId === courseId);

  if (!selectedCard) {
    return (
      <div className='dup-body'>
        <div className='dup-body-wrap'>
          <div className='course-none'>
            <img src='/img/error.svg' alt='error svg'></img>
            <p>The course you were looking for</p>
            <h1>Was not found.</h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={`dup-body ${isLoaded ? 'loaded' : ''}`}>
        <div className='dup-body-wrap'>
          <Navbar title='SavvyPool' nav1="Home" nav2="List your institute" />
          <motion.div 
            style={{ marginBottom: '1rem' }} 
            initial={{ opacity: 0, y: 100 }} 
            animate={{ opacity: 1, y: 0 }} 
            transition={{ duration: 0.5, delay: 0.2 }} 
          >
            <div className='Full-details'>
              <div className='sponsored-content'>
                <h1>Sponsored Content</h1>
              </div>
              <div className='inst-detail'>
                <div className='Institute-details'>
                  {/* <img src='https://images.pexels.com/photos/2973323/pexels-photo-2973323.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' className='insititute-banner' alt=''></img> */}
                  <img src={selectedCard.InstitutionImage} className='Institute-details-image-2' alt='instituteimg' style={{marginTop : '0px'}}/>
                  <div className='Institute-address-none'>
                    <div className='institute-website-redirect'>
                      <h1 onClick={() => {
                        switch (selectedCard.InstitutionName) {
                          default:
                            navigate(`/shortcourse/course-details/institute/${selectedCard.InstitutionName}`);
                            break;
                        }
                      }}>{selectedCard.InstitutionName}</h1>
                    </div>
                    <p>{selectedCard.City}, {selectedCard.State}, {selectedCard.Country}</p>
                  </div>
                </div>
                <h1 className='course-category'>{selectedCard.CourseName}</h1>
                <div className='course-dur'>
                  <p>Course category</p>
                  <h2>{selectedCard.CourseDuration}</h2>
                </div>
                <hr className='hr-tag'></hr>
                <div>
                  <p classname='p-category'>Course Name</p>
                  <h1>{selectedCard.CourseName}</h1>
                  <hr className='hr-tag'></hr>
                </div>
                <div>
                  <p classname='p-category'>Insititute</p>
                  <div className='web-link'>
                    <h1><a href={selectedCard.Website}>{selectedCard.InstitutionName}</a></h1>
                    <a href={selectedCard.Website}><img src='/img/External.svg' className='redirect' alt='visit-website'></img></a>
                  </div>
                  <hr className='hr-tag'></hr>
                </div>
                <div>
                  <p classname='p-category'>Course Description</p>
                  <h1>{selectedCard.CourseDescription}</h1>
                  <hr className='hr-tag'></hr>
                  <div className='course-dur'>
                    <p classname='p-category'>Mode Of Teaching</p>
                    <h2>{selectedCard.ModeOfTeaching}</h2>
                  </div>
                  <hr className='hr-tag'></hr>
                  <div>
                    <l>
                      <p classname='p-category'>Course Details</p>
                      <l>{selectedCard.ProgramCurriculum.Program1}</l>
                      <li>{selectedCard.ProgramCurriculum.Program1Descprition}</li>
                      <l>{selectedCard.ProgramCurriculum.Program2}</l>
                      <li>{selectedCard.ProgramCurriculum.Program2Descprition}</li>
                    </l>
                  </div>
                  <hr className='hr-tag'></hr>
                  <div className='course-dur'>
                    <p classname='p-category'>Course Duration</p>
                    <h2>3 years and 3 months</h2>
                  </div>
                  <hr className='hr-tag'></hr>
                  <div className='course-dur'>
                    <p classname='p-category'>Intakes</p>
                    <h2>July</h2>
                  </div>
                  <hr className='hr-tag'></hr>
                  <div className='course-dur'>
                    <p classname='p-category'>Number of teams</p>
                    <h3>6</h3>
                  </div>
                  <hr className='hr-tag'></hr>
                  <div className='course-dur'>
                    <p classname='p-category'>Course Fee</p>
                    <h2>{selectedCard.CourseFee}</h2>
                  </div>
                  <hr className='hr-tag'></hr>
                  <l>
                    <p classname='p-category'>Eligibility</p>
                    <li>Implementing, support and management of computer-based information systems.</li>
                    <li>Implementing, support and management of computer-based information systems.</li>
                    <hr className='hr-tag'></hr>
                  </l>
                  <div className='course-dur'>
                    <p classname='p-category'>Course beginned on</p>
                    <h2>July 2018</h2>
                  </div>
                  <hr className='hr-tag'></hr>
                  <div>
                    <p classname='p-category'>Common Programs</p>
                    <h2>{selectedCard.CourseShortName}</h2>
                  </div>
                  <hr className='hr-tag'></hr>
                  <div>
                    <p classname='p-category'>Application Timelines</p>
                    <h2>January/June/September</h2>
                  </div>
                  <hr className='hr-tag'></hr>
                  <l>
                    <p classname='p-category'>Available Scholarship</p>
                    <li>Directors Scholarship</li>
                    <li>IID Fellowship</li>
                    <hr className='hr-tag'></hr>
                  </l>
                  <div>
                    <p classname='p-category'>Accomodations Facilities</p>
                    <h2>Yes, available in central campus</h2>
                    <hr className='hr-tag'></hr>
                    <p classname='p-category'>Recreations</p>
                    <h2>Football, Tennis, Hockey & Chess</h2>
                  </div>
                  <hr className='hr-tag'></hr>
                  <div className='course-dur'>
                    <p classname='p-category'>Contact Info</p>
                    <h2>None</h2>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          <Contact />
        </div>
      </div>
    </>
  );
}
