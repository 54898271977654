import React from 'react'
import '../App.css'
export default function Cards({
  InstitutionName,
  InstitutionImage,
  // CourseShortName,this field is called but never used cuz ith renders the respective page
  CourseName,
  CourseDescription,
  CourseFee,
  CourseDuration,
  onClick
}) {
  // const randomColor = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
  //     Math.random() * 256
  //   )}, ${Math.floor(Math.random() * 256)}, 0.3)`
  return (
    <>
      <div className='dynamic-cards' onClick={onClick}>
        <div className='card-institute'>
          <div className='institute-card-title'>
            <img src={InstitutionImage} className='institute-logo' alt='institute-logo'></img>
            <h1>{InstitutionName}</h1>
          </div>
          <div className='Arrow-div'>
            <img src='/img/Arrow.svg' alt='close'></img>
          </div>
        </div>
        <hr className='card-hr'></hr>
        <div className='card-category'>
          {/* <p className='card-p'>{CourseShortName}</p> */}
          <h1 className='card-title'>{CourseName}</h1>
          <p className='card-desc'>{CourseDescription}</p>
        </div>
        {/* <hr className='card-hr'></hr> */}
        <div className='card-footer'>
          <div className='course-fee'>
            <h1 className='footer-title'>Course Fee</h1>
            <p>{CourseFee}</p>
          </div>
          <div className='vl'></div>
          <div className='course-duration'>
            <h1 className='footer-title'>Course Duration</h1>
            <p>{CourseDuration}</p>
          </div>
        </div>
      </div>

    </>
  )
}