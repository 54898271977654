import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Contact from '../components/Contact';
import Navbar from '../components/Navbar';
import { useCardData } from '../components/CourseDataContext';
import Cards from '../components/Cards';
export default function InstituteHomepage() {
    const navigate = useNavigate();
    const { cardData } = useCardData();
    const { institutionName } = useParams();
    let filteredCards = [];
    switch (institutionName) {
        // case 'Acodemy':
        // case 'Vipra Animation Studios and Academy':
        // case 'gra-des':
        // case 'cma':
        // case 'acc':
        // case 'int-des':
        // case 'animation':
        // case 'ui':
        // case 'web-des':
        // case 'travel':
        //     filteredCards = cardData.filter(cards => cards.InstitutionName === institutionName);
        //     break;
        default:
            filteredCards = cardData.filter(cards => cards.InstitutionName === institutionName);
            break;
    }
    // Find the card object with the matching courseId
    const selectedCard = cardData.find((card) => card.InstitutionName === institutionName);
    // Check if a card with the specified courseId was found
    if (!selectedCard) {
        // Handle the case when the courseId doesn't match any card
        return (
            <div className='dup-body'>
                <div className='dup-body-wrap'>
                    <div className='course-none'>
                        <img src='/img/error.svg' alt='error svg'></img>
                        <p>The course you were looking for</p>
                        <h1>Was not found....</h1>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            <div className="dup-body">
                <div className='dup-body-wrap'>
                    <Navbar title='SavvyPool' nav1="Home" nav2="List your institute" />
                    <div className='Full-details'>
                        <div className='sponsored-content'>
                            <h1>Sponsored Content</h1>
                            {/* {filteredCards.map((cards, index) => {
                                if (cards.CourseShortName === selectedCard.CourseShortName) {
                                    if (index < 2) {
                                        return (
                                            <Cards
                                                key={index}
                                                InstitutionImage={cards.InstitutionImage}
                                                InstitutionName={cards.InstitutionName}
                                                CourseShortName={cards.CourseShortName}
                                                CourseName={cards.CourseName}
                                                CourseFee={cards.CourseFee}
                                                CourseDuration={cards.CourseDuration}
                                                CourseId={cards.CourseId}
                                                onClick={() => {
                                                    switch (cards.CourseId) {
                                                        default:
                                                            navigate(`/shortcourse/course-details/${cards.CourseId}`);
                                                            break;
                                                    }
                                                }}
                                            />
                                        );
                                    }
                                }
                                return null;
                            })} */}
                        </div>
                        <div className='inst-detail'>
                            <div className='Institute-details'>
                                <img src='https://images.pexels.com/photos/2973323/pexels-photo-2973323.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' className='insititute-banner' alt=''></img>
                                <img src={selectedCard.InstitutionImage} className='Institute-details-image' alt='instituteimg' style={{position: 'absolute'}}/>
                                <div className='Institute-address'>
                                    <div className='institute-website-redirect' title='visit website'>
                                        <h1>{selectedCard.InstitutionName}</h1><a href={selectedCard.Website}><img src='/img/External.svg' className='redirect' alt='visit-website'></img></a>
                                    </div>

                                    <p>{selectedCard.City}, {selectedCard.State}, {selectedCard.Country}</p>
                                </div>
                            </div>
                            <h1 className='provided-courses-h1'>Courses by {selectedCard.InstitutionName} </h1>
                            <div className='provided-courses'>
                                {filteredCards.map((cards, index) => (
                                    <Cards
                                        key={index}
                                        InstitutionImage={cards.InstitutionImage}
                                        InstitutionName={cards.InstitutionName}
                                        CourseShortName={cards.CourseShortName}
                                        CourseName={cards.CourseName}
                                        CourseFee={cards.CourseFee}
                                        CourseDuration={cards.CourseDuration}
                                        CourseId={cards.CourseId}
                                        onClick={() => {
                                            switch (cards.CourseId) {
                                                // case '1':
                                                // case '2':
                                                //     navigate(`/shortcourse/course-details/${cards.CourseId}`);
                                                //     break;
                                                default:
                                                    navigate(`/shortcourse/course-details/${cards.CourseId}`);
                                                    break;
                                            }
                                        }}
                                    />
                                ))}
                            </div>
                        </div>

                    </div>
                    <Contact />
                </div>
            </div>
        </>
    )
}
