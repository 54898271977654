import { useEffect, useState } from 'react';
import axios from 'axios';
import Apicard from './Apicard';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import Multiselect from 'multiselect-react-dropdown';

const ApiData = () => {
  const [originalData, setOriginalData] = useState([]); // Store the original data
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [universityFilter, setUniversityFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [locations, setLocations] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    // Initial API request
    fetchData();
  }, []); // Empty dependency array to trigger the request only once on component mount

  const fetchData = () => {
    axios
      .post('http://ec2-65-0-21-186.ap-south-1.compute.amazonaws.com:9000/api/v1/search/main', {
        entity: 'courses',
        page: 0,
        value: universityFilter,
        filter: null,
      })
      .then((response) => {
        console.log('API Response:', response.data);
        console.log('HTTP Status:', response.status);

        const responseData = response.data && response.data.data;
        const universitiesData = responseData && (responseData.universities || responseData.courses) || [];
        setOriginalData(universitiesData);

        // Extract unique locations from the data and update the state
        const uniqueLocations = responseData.available_filters.location || [];
        setLocations(uniqueLocations);

        // const uniqueUniversities = universitiesData.filter(
        //   (university , index, self ) => index ===self.findIndex((u) => u.university_name === university.university_name)
        // );
        // setOriginalData(uniqueUniversities);

        filterData(universityFilter);
      })
      .catch((err) => {
        setError(err);
        console.error('Error in API request:', err);
      });
  };

  const handleUniversityChange = (event) => {
    const selectedValue = event.target.value;
    setUniversityFilter(selectedValue);
    filterData(selectedValue, locationFilter);
  };

  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value;
    setLocationFilter(selectedLocation);
    filterData(universityFilter, selectedLocation);
  };

  const filterData = (universityValue, locationValue) => {
    const filteredItems = originalData.filter((item) => {
      const universityNameMatch = universityValue === '' || item.university_name.toLowerCase().includes(universityValue.toLowerCase());
      const locationMatch = locationValue === '' || (item.city && item.city.toLowerCase().includes(locationValue.toLowerCase()));
  
      return universityNameMatch && locationMatch;
    });
  
    setFilteredData(filteredItems);
  };
  
  // const handleCardClick = (e) => {
  //   const navigate = useNavigate()
  // }
  return (
    <div className='dup-body-wrap'>
      <Navbar title='SavvyPool' nav1='Home' nav2='List your institute' />
      <hr className='hr'></hr>
      <div className='university-course-container'>
        <div className='searchpage-container'>
          <div className='filter-container'>
            <div className='filters'>
              <div className='custom-select'>
                <select value={universityFilter} onChange={handleUniversityChange}>
                  <option value=''>Institution</option>
                  {originalData.
                    // filter(
                    //   (university,index,self) => (index === self.findIndex((u) => u.university_name === university))
                    // ).
                    map((university) => (
                      <option key={university.user_id} value={university.university_name}>
                        {university.university_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className='filters'>
              <div className='custom-select'>
                <select value={locationFilter} onChange={handleLocationChange}>
                  <option value=''>Location</option>
                  {locations.map((location, index) => (
                    <option key={index} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className='university-course'>
          {error && <div>Error: {error.message}</div>}
          {filteredData.length > 0 ? (
            filteredData.map((university) => (
              <div>
                <Apicard
                  key={university.user_id || university.Id}
                  University={university.university_name}
                  CourseName={university.course_name}
                  CourseFee={university.course_fee}
                  CourseFeeType={university.course_fee_type}
                  logo={university.logo_url}
                  city={university.city}
                  state={university.state}
                  country={university.country}
                  CourseId={university.course_id}
                />
              </div>
            ))
          ) : (
            originalData.map((university) => (
              <div onClick={() => {
                navigate(`/course/${university.course_id}`);
              }}>
                <Apicard
                  key={university.user_id || university.Id}
                  University={university.university_name}
                  CourseName={university.course_name}
                  CourseFee={university.course_fee}
                  CourseFeeType={university.course_fee_type}
                  logo={university.logo_url}
                  city={university.city}
                  state={university.state}
                  country={university.country}
                  CourseId={university.course_id}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ApiData;
