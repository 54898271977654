import React from 'react';
import { Link } from 'react-router-dom'
export default function EditInstitute() {
    return (
        <div className='institute-details-container'>
             <button>
                <Link to={{ pathname: '/admin' }}>
                    <img src='../img/back-arrow.png' alt='back-arrow' className='back-arrow' />
                </Link>
            </button>
            <div className='edit-details'>
                <h1 style={{fontSize : '30px'}}>Institute List</h1>
                <div style={{display : 'flex' , flexWrap : 'wrap', gap : '30px', margin : '30px' , alignItems : 'center' , justifyContent : 'center'}}>
                    <div className='edit-cards'>
                        <div className='edit-img'></div>
                        <div className='edit-instname'>
                            <h1>BlueLearn institute</h1>
                            <p>Mumbai,MH</p>
                        </div>
                    </div>
                    <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>
                <div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div><div className='edit-cards'>
                    <div className='edit-img'></div>
                    <div className='edit-instname'>
                        <h1>BlueLearn institute</h1>
                        <p>Mumbai,MH</p>
                    </div>
                </div>

                </div>

            </div>
        </div>
    )
}