import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import Cards from '../components/Cards';
import { useCardData } from './CourseDataContext';
import { motion } from 'framer-motion';

const fullCourseNames = {
  'web-dev': 'Web Development',
  'dig-mar': 'Digital Marketing',
  'gra-des': 'Graphic Design',
  'cma': 'Content Marketing',
  'acc': 'Accounting',
  'int-des': 'Interior Design',
  'animation': 'Animation',
  'ui': 'User Interface Design',
  'web-des': 'Web Design',
  'travel': 'Travel and Tourism',
};

export default function DataCard() {
    const { shortname } = useParams();
    const navigate = useNavigate();
    const { cardData } = useCardData();
    let filteredCards = [];

    switch (shortname) {
        case 'web-dev':
        case 'dig-mar':
        case 'gra-des':
        case 'cma':
        case 'acc':
        case 'int-des':
        case 'animation':
        case 'ui':
        case 'web-des':
        case 'travel':
            filteredCards = cardData.filter(cards => cards.CourseShortName === shortname);
            break;
        default:
            filteredCards = cardData.filter(cards => cards.CourseShortName);
            break;
    }

    const selectedCourseName = fullCourseNames[shortname] || shortname;

    return (
        <>
        <div className='course-meter'>
            <h1 className='Container-title'>{selectedCourseName}</h1>
            <p>( Courses found : {filteredCards.length} )</p>
        </div>
        <div className='card-container'>
            {filteredCards.map((cards, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.09 }}
                >
                    <Cards
                        InstitutionImage={cards.InstitutionImage}
                        InstitutionName={cards.InstitutionName}
                        CourseShortName={cards.CourseShortName}
                        CourseName={cards.CourseName}
                        CourseFee={cards.CourseFee}
                        CourseDuration={cards.CourseDuration}
                        CourseId={cards.CourseId}
                        onClick={() => {
                            switch (cards.CourseId) {
                                default:
                                    navigate(`/shortcourse/course-details/${cards.CourseId}`);
                                    break;
                            }
                        }}
                    />
                </motion.div>
            ))}
        </div>
        </>
    )
}
